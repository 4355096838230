import { render, staticRenderFns } from "./StartSessionOverlay.vue?vue&type=template&id=34421c52&scoped=true&lang=pug"
import script from "./StartSessionOverlay.vue?vue&type=script&lang=ts"
export * from "./StartSessionOverlay.vue?vue&type=script&lang=ts"
import style0 from "./StartSessionOverlay.vue?vue&type=style&index=0&id=34421c52&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34421c52",
  null
  
)

export default component.exports