<template lang="pug">
.start-session-container 
  .start-session-content(:class="containerClass")
    .start-session-header {{ header }}
    .start-session-info
      .start-session-image-column
        .start-session-image-container
          img(:src="course.courseIcon")
      .start-session-info-column
        .start-session-course-title {{ event.courseName }}
          .start-session-subheader(v-if="!isPractice") {{ eventTitle }}
        .start-session-date(v-if="!isPractice") {{ localStart }}
        template(v-if="isPractice")
          .practice-session-text Practice Mode is intended for practice and preparation and should not be used for actual training.
        .confirm-overlay-button(data-name="confirm-overlay-button", @mousedown="overlayConfirmed") {{ buttonText }}
</template>
<script lang="ts">
import Authorization from "@/auth/authorization";
import { SetRootState, StartClassroom } from "@/types";
import { LocalStorageFields } from "@/types/base";
import { EventContentModel } from "@cruciallearning/puddle";
import moment from "moment";
import { Vue, Component } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
@Component({
  computed: {
    ...mapState(["isClassroomStarted"]),
    ...mapState("EventModule", ["event"]),
    ...mapState("CourseModule", ["course"]),
    ...mapGetters("EventModule", ["effectiveSessionNumber", "currentSessionStart", "isRestarting"]),
  },
  methods: {
    ...mapMutations(["setRootState"]),
    ...mapActions(["startClassroom"]),
  },
})
export default class StartSessionOverlay extends Vue {
  private readonly event!: EventContentModel;
  private readonly effectiveSessionNumber!: number;
  private readonly setRootState!: SetRootState;
  private readonly currentSessionStart!: Date;
  private readonly startClassroom!: StartClassroom;
  private readonly isRestarting!: boolean;
  private readonly isClassroomStarted!: boolean;

  get localStart(): string {
    const tz = this.currentSessionStart.toLocaleDateString(undefined, { timeZoneName: "short" }).split(" ");
    let display = moment(this.currentSessionStart).format("YYYY-MM-DD, hh:mm a");
    display = display.replace("am", "a.m.");
    display = display.replace("pm", "p.m.");
    return `${display} ${tz[1]}`;
  }

  get eventTitle(): string {
    return this.event.title || this.localStart;
  }

  get isPractice(): boolean {
    return this.event.practice;
  }

  get header(): string {
    if (this.isPractice) return "Start Practice Mode";
    if (!this.isClassroomStarted) {
      const action = this.isRestarting ? "Restart" : "Start";
      return `${action} Session ${this.effectiveSessionNumber}`;
    } else {
      return `Session ${this.effectiveSessionNumber} In Progress`;
    }
  }

  get buttonText(): string {
    return !this.isPractice ? "Enter Classroom" : "Start Practice Mode";
  }

  get containerClass(): string {
    if (Authorization.isInPerson()) return "in-person-container";
    return "";
  }

  overlayConfirmed(): void {
    if (this.isPractice) {
      this.startClassroom();
    }
    const descriptor = this.isRestarting ? "-R" : "";
    const key = `${LocalStorageFields.EVENT_VISITIED}${this.event.id}`;
    localStorage.setItem(key, `${this.effectiveSessionNumber}${descriptor}`);
    this.setRootState({ firstVisitPopup: false });
  }
}
</script>
<style lang="scss" scoped>
.start-session-container {
  height: 100%;
  width: 100%;
  z-index: 500;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  color: white;
  backdrop-filter: blur(10px);

  .start-session-content {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: fit-content;
    margin: auto;
  }
  .start-session-header {
    font-weight: bold;
    color: #fff;
    font-family: "flama_condensed";
    font-size: 48px;
    font-style: normal;
    font-weight: 750;
    line-height: 120%;
    text-transform: uppercase;
  }
  .start-session-image-column {
    margin-right: 0.5rem;
  }
  .start-session-image-container {
    max-height: 50px;
    max-width: 50px;
    overflow: hidden;
    border-radius: 6px;
  }
  .start-session-course-title {
    font-size: 21px;
    font-weight: 650;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .start-session-subheader {
    margin-top: 8px;
    font-size: 18px;
    font-weight: 500;
  }
  .start-session-info-column {
    margin-left: 8px;
  }
  .start-session-date {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    background: #fff;
    color: black;
    width: fit-content;
    font-size: 16px;
    margin: 1rem 0;
  }
  .start-session-info {
    margin-top: 16px;
    display: flex;
  }
  .practice-session-text {
    margin: 32px 0;
    width: 500px;
  }
  .confirm-overlay-button {
    border-radius: 5px;
    background-color: var(--error-50);
    display: flex;
    padding: 8px 26px 10px 26px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 224px;
    cursor: pointer;
    text-transform: capitalize;
    margin-top: 32px;
    font-weight: bold;
    font-size: 14px;
    &:hover {
      background-color: var(--error-20);
    }
  }
  img {
    height: 100%;
    width: 100%;
  }
  .in-person-container {
    margin-left: 20%;
  }
}
</style>
